import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const colors = {
  maroon: "#701a27", // accent
  blue: "#068D9D", // accent
  green: "#B5B967", // secondary
  platinum: "#E7E3E0", // prim
  white: "#FFFFFF", // prim
};

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: colors.white,
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(20),
    border: "1px solid #068D9D",
  },
}))(Tooltip);

function HoverText({ toText, toTip }) {
  return (
    <div>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit"> {toTip} </Typography>
          </React.Fragment>
        }
      >
        <div> {toText} </div>
      </HtmlTooltip>
    </div>
  );
}

export default HoverText;
